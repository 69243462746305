/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Fragment, useState } from 'react';
import {
    object, string, array, shape, func,
} from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { makeStyles, Grid } from '@material-ui/core';
import { useUIDSeed } from 'react-uid';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import NUpSimplePanelContents from './NUpSimplePanelContents';
import Tiles from '../../../GraphqlComponents/GraphqlHomePage/Partials/Tiles/Tiles';
import openInNewTab from '../../../../helpers/contentstack/openInNewTab';

/*
    TODO: Create ability to add sashes and dot-whacks.
*/

const useStyles = ({
    nup, isHangTag, headingAlignment, ssrDeviceType,
}) => {
    const isMobile = ssrDeviceType.toLowerCase() === 'mobile';

    const itemsPerRow = (isMobile)
        ? nup.styles?.per_row_mobile || 2
        : nup.styles?.per_row || 3;

    const calcPanelWidth = () => {
        let widthValue = `${94 / itemsPerRow}%`;
        if (isHangTag && isMobile && itemsPerRow === 1) widthValue = '100%';
        return widthValue;
    };

    const calcPanelMaxHeight = () => {
        let maxHt = '440px';
        if ((1440 / itemsPerRow) < 440) maxHt = `${1440 / itemsPerRow}px`;
        return maxHt;
    };

    const calcPanelMarginBottom = () => {
        let rowMargin = '';
        const rowCount = (isMobile)
            ? nup.styles?.rows_mobile || 1
            : nup.styles?.rows || 1;
        if (rowCount > 1) {
            rowMargin = nup.styles?.add_grid_borders ? '0' : '30px';
        }
        if (isHangTag && isMobile) {
            rowMargin = '5px';
        }
        if (isHangTag && !isMobile) {
            rowMargin = '80px';
        }
        return rowMargin;
    };

    const calcPanelHeight = () => {
        let heightValue = `${94 / itemsPerRow}vw`;
        if (isHangTag && isMobile) {
            heightValue = '300px';
        }
        if (isHangTag && !isMobile) {
            heightValue = '320px';
        }
        // allow_panels_to_be_rectangles is used for 18f /in-the-news page
        if (nup.styles?.allow_panels_to_be_rectangles) {
            heightValue = 'auto';
        }
        return heightValue;
    };

    return makeStyles((theme) => ({
        nUp: () => ({
            maxWidth: '1400px',
            margin: nup.styles.presentation_style === 'T3 Hang Tag' ? '20px auto 60px' : theme.palette.nup?.margin,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: nup.styles.presentation_style === 'T3 Hang Tag' ? 'space-evenly' : 'space-between',
            '& *:focus': {
                outline: 'none',
            },
            '& *:focus-visible': {
                outline: '-webkit-focus-ring-color auto 1px',
            },
            '@media screen and (max-width: 600px)': {
                margin: nup.styles.presentation_style === 'T3 Hang Tag' ? '20px auto 60px' : '20px auto',
                '& > [anchor]': {
                    display: 'block',
                    margin: '0 auto 15px',
                },
            },
            '@supports (-webkit-hyphens:none)': {
                overflow: '-webkit-paged-x',
                '& a': {
                    maxHeight: 'fit-content',
                },
            },
        }),
        nUpLess: {
            maxWidth: '1400px',
            overflow: 'hidden',
            position: 'relative',
            padding: '0 10px 15px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        nupContent: {
            justifyContent: 'center',
            paddingBottom: '20px',
            borderBottom: `1px solid ${theme.palette.modalBorder}`,
        },
        nupContentWithGrid: {
            justifyContent: 'center',
            borderBottom: `1px solid ${theme.palette.modalBorder}`,
        },
        fullWidth: {
            maxWidth: '100%',
            width: '100%',
            '& $background_stroke': {
                '&::after': {
                    width: '75%',
                    margin: '0 auto',
                },
            },
        },
        header: theme.palette.nup?.header,
        headerBorder: {
            fontFamily: 'PlayfairDisplay, serif',
            fontSize: '2.5em',
            fontWeight: '400',
            textAlign: 'center',
            paddingBottom: '15px',
            margin: '0px auto 20px !important',
            borderBottom: `1px solid ${theme.palette.modalBorder}`,
        },
        hangTagHeader: () => ({
            margin: '0 auto',
            fontSize: '2.5em',
            fontWeight: '400',
            paddingBottom: '55px',
            fontFamily: 'latobold, Arial, Verdana, sans-serif',
            textAlign: headingAlignment,
            color: '#4b93af',
        }),
        borderLeft: {
            borderLeft: '1px solid #ececec',
        },
        panel: () => ({
            textDecoration: 'none',
            backgroundSize: 'cover',
            textAlign: 'center', // TODO: needs to be adjustable in CMS> HD is not centered
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: isHangTag ? '8px' : '',
            paddingLeft: isHangTag ? '8px' : '',
            width: calcPanelWidth(),
            maxHeight: calcPanelMaxHeight(),
            height: calcPanelHeight(),
            marginBottom: calcPanelMarginBottom(),
            '&:focus:not(:link:active)': {
                outline: '-webkit-focus-ring-color auto 5px',
            },
            '&:first-child': {
                paddingLeft: '0', // fallback for ie11
            },
            '@media screen and (min-width: 1440px)': {
                '& > div': {
                    height: (nup.styles?.allow_panels_to_be_rectangles) ? 'auto' : `${1440 / (nup.styles?.per_row || nup.entry.length)}px`,
                },
            },
            '@media screen and (max-width: 1439px)': {
                '& > div': {
                    height: (nup.styles?.allow_panels_to_be_rectangles) ? 'auto' : `${(100 - 5) / (nup.styles?.per_row || nup.entry.length)}vw`,
                },
            },
            '@media screen and (max-width: 1000px)': {
                '& [message]': {
                    width: '100%',
                },
            },
            '@media screen and (max-width: 768px)': {
                paddingRight: isHangTag ? '0' : '',
                paddingLeft: isHangTag ? '0' : '',
            },
            '@media screen and (max-width: 600px)': {
                margin: '0 auto',
                '&:not(:first-child)': {
                    paddingLeft: '0',
                },
                '& > div': {
                // eslint-disable-next-line no-nested-ternary
                    minHeight: (nup.styles?.allow_panels_to_be_rectangles && !isHangTag)
                        ? 'auto'
                        : isHangTag ? '300px' : `${(100 - 5) / (nup.styles?.per_row_mobile || 2)}%`,
                },
            },
        }),
        viewMoreWrapper: {
            width: 'calc(100vw)',
            bottom: '0',
            height: '40px',
            display: 'block',
            position: 'relative',
        },
        viewMoreGradientWrapper: {
            left: '0',
            width: 'calc(100vw)',
            bottom: '0',
            height: '200px',
            display: 'block',
            position: 'absolute',
            backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .9) 60%)',
        },
        viewMore: {
            left: 'calc(50% - 0px)',
            color: theme.palette.common?.white,
            width: 'calc(35vw)',
            transform: 'translate(-50%, 0)',
            fontSize: '16px',
            fontWeight: '700',
            borderRadius: '5px',
            backgroundColor: theme.palette.text?.primary,
            lineHeight: '1.75',
            textTransform: 'uppercase',
            border: '0',
            cursor: 'pointer',
            outline: 'none',
            position: 'absolute',
            bottom: '10px',
            userSelect: 'none',
        },
        Standard: theme.palette.nup?.Standard,
        Medium: theme.palette.nup?.Medium,
        Small: theme.palette.nup?.Small,
        Auto: {
            maxWidth: '100%',
        },
        categoryPer3row: {
            maxHeight: '700px',
        },
        categoryPer4row: {
            maxHeight: '800px',
        },
        borderBetweenRows: {
            borderBottom: `1px solid ${theme.palette.modalBorder}`,
            margin: '20px auto',
        },
    }));
};

const NUpSimpleResponsive = ({
    headingAlignment, data, track, ssrDeviceType, presentation_family,
}) => {
    if (data.reference?.[0]) {
        const [expanded, setExpanded] = useState(true);

        const nup = data.reference[0];

        const isMobile = ssrDeviceType.toLowerCase() === 'mobile';
        const isDesktop = !isMobile;
        const isHangTag = nup.styles.presentation_style === 'T3 Hang Tag';
        const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));

        const classes = useStyles({
            nup, isHangTag, headingAlignment, ssrDeviceType,
        })();

        let itemList = (nup.entry instanceof Array) ? nup.entry : [nup.entry]; // wrapping in array if we are getting object
        const loadCssRowBase = ((nup.styles?.per_row || nup?.mobile_row_item_count) === 3 ? classes.categoryPer3row : classes.categoryPer4row);

        const mobileItemLimit = (nup.styles?.rows_mobile || 1) * (nup.styles?.per_row_mobile || 2);
        const desktopItemLimit = (nup.styles?.rows || 1) * (nup.styles?.per_row || 3);
        itemList = (isMobile)
            ? itemList.slice(0, mobileItemLimit)
            : itemList.slice(0, desktopItemLimit);

        const seed = useUIDSeed();

        const shouldHaveLeftBorder = (index) => isDesktop && nup?.styles?.add_grid_borders && (index % (nup?.styles?.per_row || 3)) !== 0;

        const nupSimplePanel = (panelItem, index) => {
            const  showOnMobile =  isMobile ? !panelItem.hide_on_mobile : true;
            let simplePanel = '';
            if (showOnMobile) {
                simplePanel =                    (
                    <Link
                        key={seed(panelItem)}
                        data-testId="pannelItem"
                        anchor=""
                        to={{ pathname: panelItem.linking?.link?.href }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...openInNewTab(panelItem.linking?.link?.href)}
                        title={panelItem.linking?.link?.title}
                        className={`${classes.panel} ${index !== 0 && shouldHaveLeftBorder(index) ? classes.borderLeft : ''}`}
                        data-ga-category={panelItem?.linking?.tracking_event_category || ''}
                        data-ga-action={panelItem?.linking?.tracking_event_action || ''}
                        data-ga-label={panelItem?.linking?.tracking_event_label || ''}
                        onClick={() => {
                            track({
                                eventCategory: panelItem?.linking?.tracking_event_category || '',
                                eventAction: panelItem?.linking?.tracking_event_action || '',
                                eventLabel: panelItem?.linking?.tracking_event_label || '',
                            });
                        }}
                    >
                        <NUpSimplePanelContents
                            data={data}
                            panelItem={panelItem}
                            classes={classes}
                            isMobile={isMobile}
                        />
                    </Link>
                );
            }
            return simplePanel;
        };

        const renderPanels = (panels) => (
            <>
                {panels.map((panelItem, index) => {
                    if (panelItem.copy || panelItem.cta_copy || panelItem.background_image) {
                        const needsBottomBorder = (isDesktop && nup?.styles?.add_grid_borders && ((index + 1) % (nup?.styles?.per_row || 3)) === 0);
                        return (
                            <Fragment key={panelItem.linking.link.title}>
                                {nupSimplePanel(panelItem, index)}
                                {(needsBottomBorder) && (<Grid item xs={12} className={classes.borderBetweenRows} />)}
                            </Fragment>
                        );
                    }
                    return null;
                })}
            </>
        );
        const additionalNupStyles = (nupClasses, nupData) => {
            let nupStyles = '';
            if (nupData.styles.full_width) {
                nupStyles = nupClasses.fullWidth;
            }
            if ((nupData.styles.per_row === 3) && nupData.heading) {
                nupStyles = ffHasHomepageGridStructure ? nupClasses.nupContentWithGrid : nupClasses.nupContent;
            }

            return nupStyles;
        };

        if (nup?.tiles && isMobile) return <Tiles track={track} styleProps={classes} block={nup} />;
        if (nup?.tiles && isDesktop) return <></>;

        return (
            <>
                <div
                    className={(presentation_family === 'food' && expanded && ((isMobile && nup.styles?.rows_mobile > 2) || (isDesktop && nup?.entry?.length > 10)))
                        ? `${classes.nUpLess} ${loadCssRowBase}`
                        : `${classes.nUp} ${additionalNupStyles(classes, nup)}`}
                    data-container="Nup Simple"
                >
                    { nup.heading && <h3 className={`${classes.header} ${(isHangTag) ? classes.hangTagHeader : classes.headerBorder}`}>{nup.heading}</h3> }
                    {renderPanels(itemList)}
                    {isMobile && presentation_family === 'food' && (nup.styles?.rows_mobile > 2 || nup?.entry?.length > 10) && (
                        <div className={expanded ? classes.viewMoreGradientWrapper : classes.viewMoreWrapper}>
                            <button type="button" className={classes.viewMore} onClick={() => { setExpanded(!expanded); }} onKeyDown={() => { }}>
                                {expanded ? <span>View More</span> : <span>View Less</span>}
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }
    return <></>;
};

NUpSimpleResponsive.propTypes = {
    data: shape({
        entry: shape({
            linking: object,
            copy: array,
            cta_copy: array,
            background_image: object,
            background_image_mobile: object,
        }).isRequired,
        styles: object,
    }).isRequired,
    headingAlignment: string.isRequired,
    presentation_family: string.isRequired,
    track: func.isRequired,
    ssrDeviceType: string.isRequired,
};

const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
});

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(NUpSimpleResponsive);
