/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import {
    object, array, shape, bool, func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import LazyLoad from 'react-lazyload';
import Button from '@material-ui/core/Button';
import Caret from '@material-ui/icons/ArrowForwardIos';
import { useUIDSeed } from 'react-uid';
import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import generateValidCss from '../../../../helpers/contentstack/generateValidCss';
import transformRenderer from '../../../../helpers/markdown/characterTransformer';
import Copy from '../../Copy';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ResponsiveImage from '../../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import Tiles from '../../../GraphqlComponents/GraphqlHomePage/Partials/Tiles/Tiles';
import openInNewTab from '../../../../helpers/contentstack/openInNewTab';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';

/*
    TODO: Create ability to add sashes and dot-whacks.
          Create mobile version
          Define # per row (maybe unnecessary)
*/
const styles = (theme) => ({
    nUp: {
        margin: '20px auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '& *:focus': {
            outline: 'none',
        },
    },
    panel: {
        height: 'auto',
        maxWidth: '400px',
        textDecoration: 'none',
        textAlign: 'center', // TODO: needs to be adjustable in CMS> HD is not centered
        display: 'flex',
        alignContent: 'top',
        // boxShadow: '0 20px 20px yellow',
        '& > div': {
            // height: '30vw', // apply only when not carousel
        },
        marginBottom: '30px',
        '& [message]': {
            width: '100%',
            '& *': {
                fontSize: '16px',
            },
        },
        '& [message] p': {
            marginTop: '0 !important',
            paddingTop: '0 !important',
        },
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        transition: 'all .3s',
        '& img': {
            minHeight: '160px',
        },
    },
    messageWrapper: {
        padding: '10px 0',
        width: '100%',
        '& u': {
            fontSize: '0.7em',
        },
        '& p': {
            margin: '0 auto 10px',
        },
    },
    messageWrapperTab: {
        width: '80%',
        margin: '0 auto',
        padding: '10px',
        '& u': {
            fontSize: '0.7em',
        },
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    slider: {
        '& img': {
            margin: '0 auto 0',
            maxWidth: '100%',
            minHeight: 'auto',
            '&:hover': {
                transform: 'scale(1.1)',
            },
        },
    },
    sliderContainer: {
        position: 'relative',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 15px',
        },
        '& .slick-slide': {
            display: 'inline-block',
        },
        '& .slick-prev, & .slick-next': {
            transform: 'translate(0,-50%)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            fontSize: 0,
            lineHeight: 0,
            top: '42%',
            width: 30,
            height: 30,
            position: 'absolute',
            background: 'transparent',
        },
        '& .slick-prev': {
            left: 0,
        },
        '& .slick-next': {
            right: 0,
        },
        '& $panel': {
            width: '100%',
        },
    },
    Standard: theme.palette.nup?.Standard,
    Medium: theme.palette.nup?.Medium,
    Small: theme.palette.nup?.Small,
});
const fontFamilyParsed = (fontData) => {
    if (!fontData) return '';
    const fontArr = fontData.split(':');
    if (fontArr?.[1]) {
        return fontArr[1].replace(';', '');
    }
    return '';
};
const detailsAsObj = (promo) => {
    const data = [
        {
            details_link: {
                details_link: promo,
                presentation: 'Single Line',
            },
        },
    ];
    return data;
};
const elementWidth = (data, isTile, presentationStyleCarousel) => {
    /*
     * @param {obj} - the entire data prop
     * @param {bool} - news tile boolean
     * @return {bool} - carousel boolean
     */
    let widthValue = '400px';
    let margin = '10px auto 10px';
    let border = '';

    // TODO: Implement width logic for tile and carosel probably.
    const itemPerRow = data?.reference?.[0].styles.per_row || 1;
    widthValue = `${(99.5 / itemPerRow)}%`;

    if (isTile || presentationStyleCarousel) {
        widthValue = '100%';
        margin = '0px';
        border = '1px solid #ececec';
    }

    return { width: widthValue, margin, borderBottom: border };
};
const NUpSimpleCarousel = ({
    classes, data, isBot, track,
}) => {
    if (data.reference?.[0]) {
        const nup = data.reference[0];
        const isMobile = useSelector(getSSRDeviceType) === 'mobile';

        let  itemList = (nup.entry instanceof Array) ? nup.entry : [nup.entry]; // wrapping in array if we are getting object
        itemList = nup.styles?.rows > 0 ? itemList.slice(0, (nup.styles?.number_of_rows_mobile || 3) * (nup.styles?.number_per_row_mobile || 1)) : itemList;

        const seed = useUIDSeed();
        let messageWrapper = classes.messageWrapper;
        const presentationStyleCarousel = nup.styles?.presentation_style === 'Carousel';
        if (nup.styles.message_bg_style === 'Tab') { messageWrapper = classes.messageWrapperTab; }

        const nupSimplePanel = (panelItem, isTile = false) => {
            const  showOnMobile =  isMobile ? !panelItem.hide_on_mobile : true;
            let simplePanel = '';
            if (showOnMobile) {
                simplePanel = (
                    <Link
                        key={seed(panelItem)}
                        anchor=""
                        to={{ pathname: panelItem.linking?.link?.href }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...openInNewTab(panelItem.linking?.link?.href)}
                        title={panelItem.linking?.link?.title}
                        className={classes.panel}
                        style={elementWidth(data, isTile, presentationStyleCarousel)}
                        data-ga-category={panelItem?.linking?.tracking_event_category || ''}
                        data-ga-action={panelItem?.linking?.tracking_event_action || ''}
                        data-ga-label={panelItem?.linking?.tracking_event_label || ''}
                        data-testid="nup-carousel-panel"
                        aria-hidden="true"
                        tabIndex="-1"
                        onClick={() => {
                            track({
                                eventCategory: panelItem?.linking?.tracking_event_category || '',
                                eventAction: panelItem?.linking?.tracking_event_action || '',
                                eventLabel: panelItem?.linking?.tracking_event_label || '',
                            });
                        }}
                    >
                        {panelItem.background_image || panelItem.background_image_mobile
                            ? (
                                <>
                                    <div
                                        className={classes.imageContainer}
                                    >
                                        {isBot && <img src={panelItem.background_image_mobile?.url || panelItem.background_image?.url} alt={panelItem.background_image_mobile?.filename || panelItem.background_image?.filename} isBot="true" />}
                                        <LazyLoad>
                                            <ResponsiveImage
                                                path={panelItem.background_image_mobile?.url || panelItem.background_image?.url}
                                                alt={panelItem.background_image_mobile?.description || panelItem.background_image?.filename || 'Background Image'}
                                                style={isTile ? { width: '100%', marginBottom: '0px', paddingTop: '20px' } : { width: '100%' }}
                                                params={{}}
                                            />
                                        </LazyLoad>
                                        <div
                                            className={messageWrapper}
                                            message=""
                                            style={{
                                                fontSize: `${nup.styles?.font_size}em`,
                                                minHeight: `${nup.styles?.message_background_height}px`,
                                                fontFamily: fontFamilyParsed(nup.styles?.copy_font),
                                                color: nup.styles?.copy_color?.color,
                                            }}
                                        >
                                            {panelItem.copy && panelItem.copy.map((k) => <ReactMarkdown renderers={transformRenderer} source={k} />)}
                                            {panelItem.details_link && <p><Copy data={detailsAsObj(panelItem.details_link)} /></p>}
                                            {panelItem.cta_copy && (
                                                <Button
                                                    className={classes.btnStyle}
                                                    text
                                                    endIcon={<Caret>content</Caret>}
                                                    style={{
                                                        fontFamily: fontFamilyParsed(nup.styles?.copy_font),
                                                    }}
                                                >{panelItem.cta_copy}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </>
                        /* Added Copy components instead of manual switch case
                    to avoid code repetition and to add more functionality to n-up panel.
                    We can use Panel component directly but background image logic has not been implemented in it yet */
                            )
                            : (
                                <div className={classes[data?.reference[0]?.presentation_style]} style={generateValidCss(panelItem.container_attributes, true)}>
                                    <Copy data={panelItem.message_collection} />
                                </div>
                            )}
                    </Link>
                );
            }
            return simplePanel;
        };
        if (nup?.tiles) {
            return <Tiles track={track} styleProps={classes} block={nup} />;
        }

        return (
            <Slider
                dots={false}
                infinite
                speed={500}
                slidesToShow={5}
                slidesToScroll={1}
                nextArrow={<Caret />}
                prevArrow={<ArrowBackIosIcon />}
                className={`carousel-container ${classes.sliderContainer}`}
                responsive={[
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                ]}
            >
                { itemList.map((panelItem) => (
                    panelItem.background_image
                && (
                    <div key={panelItem.linking.link.href} className={classes.slider}>
                        { nupSimplePanel(panelItem)}
                    </div>
                )
                ))}
            </Slider>
        );
    }
    return <>n_up_simple not loaded correctly</>;
};
NUpSimpleCarousel.propTypes = {
    data: shape({
        entry: shape({
            linking: object,
            copy: array,
            cta_copy: array,
            background_image: object,
            background_image_mobile: object,
        }).isRequired,
        styles: object,
    }).isRequired,
    classes: object.isRequired,
    isBot: bool.isRequired,
    track: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

const enhance = compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
);
export default enhance(NUpSimpleCarousel);
