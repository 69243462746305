/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-nested-ternary */

import React from 'react';
import {
    object, array, shape, bool, string,
} from 'prop-types';
import Caret from '@material-ui/icons/ArrowForwardIos';
import ReactMarkdown from 'react-markdown';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import generateValidCss from '../../../../helpers/contentstack/generateValidCss';
import Copy from '../../Copy';
import transformRenderer from '../../../../helpers/markdown/characterTransformer';

const fontFamilyParsed = (fontData) => {
    if (!fontData) return '';
    const fontArr = fontData.split(':');
    if (fontArr?.[1]) {
        return fontArr[1].replace(';', '');
    }
    return '';
};
const detailsAsObj = (promo) => {
    const data = [
        {
            details_link: {
                details_link: promo,
                presentation: 'Single Line',
            },
        },
    ];
    return data;
};

const useStyles = makeStyles(() => ({
    imageContainer: ({ nup }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        transition: 'all .3s',
        height: nup.styles.presentation_style === 'T3 Hang Tag' ? '300px' : 'auto',
        '&:hover': {
            opacity: nup.styles.presentation_style === 'T3 Hang Tag' ? '' : '.94',
            '& button': {
                textDecoration: nup.styles.presentation_style === 'T3 Hang Tag' ? 'none' : 'underline',
            },
        },
        '& p': {
            margin: '0',
        },
    }),
    imageBackground: ({ panelItem }) => ({
        backgroundImage: `url(${panelItem.background_image.url}?auto=webp)`,
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        width: '100%',
        '@media screen and (max-width: 600px)': {
            backgroundImage: `url(${panelItem?.background_image_mobile?.url}?auto=webp)`,
        },
    }),
    containerAttributes: ({ panelItem }) => generateValidCss(panelItem.container_attributes, true), // maybe remove - prop no existe
    newsTileImage: {
        width: '100%',
        marginBottom: '0',
    },
    messageWrapper: ({ nup, isMobile }) => ({
        backgroundPositionY: 'top',
        fontSize: `${nup.styles?.font_size}em`,
        minHeight: isMobile ? (`${nup.styles?.message_background_height_mobile}px` || 'auto') : (`${nup.styles?.message_background_height}px` || 'auto'),
        fontFamily: fontFamilyParsed(nup.styles?.copy_font),
        color: nup.styles?.copy_color?.color,
        '& button:hover': {
            backgroundColor: 'transparent', // overwrite for mui button
        },
    }),
    messageWrapperDefault: ({ nup }) => ({
        backgroundColor: `${nup.styles?.message_background_color?.color}`,
        opacity: '.9',
        alignSelf: 'flex-end',
        padding: '10px 0',
        width: '100%',
        '& u': {
            fontSize: '0.7em',
        },
    }),
    messageWrapperTab: ({ nup }) => ({
        backgroundColor: `${nup.styles?.message_background_color?.color}`,
        opacity: '.9',
        width: '80%',
        margin: '0 auto',
        paddingTop: '10px',
        paddingRight: '5px',
        paddingBottom: '5px',
        paddingLeft: '5px',
        '& u': {
            fontSize: '0.7em',
        },
        '@media screen and (max-width: 599px)': {
            width: '100%',
            paddingTop: '11px',
            paddingRight: '5px',
            paddingBottom: '3px',
            paddingLeft: '5px',
        },
    }),
    hangTagContainer: {
        '& div': {
            '& div': {
                textAlign: 'left',
            },
        },
    },
    ctaCaret: {
        marginRight: '5px',
        alignItems: 'center',
        lineHeight: '1em',
    },
    hangTagMessage: ({ nup }) => ({
        left: '-1px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        transformOrigin: 'left',
        backgroundSize: 'contain',
        backgroundImage: nup.styles.per_row > 2 ? `url(${nup.styles.message_background_image_mobile?.url}?auto=webp)` : `url(${nup.styles.message_background_image?.url})?auto=webp)`,
        // mobile breakpoint for panels is 600px
        '@media screen and (min-width: 1400px)': {
            height: nup.styles?.per_row > 2 ? '106px' : '120px',
            lineHeight: '.9',
            bottom: nup.styles?.per_row > 2 ? '-32px' : '-58px',
            paddingLeft: nup.styles?.per_row > 2 ? '21%' : '187px',
            paddingRight: nup.styles?.per_row > 2 ? '17%' : '142px',
        },
        '@media screen and (max-width: 1399px) and (min-width: 600px)': {
            maxWidth: '53vw',
        },
        '@media screen and (max-width: 1399px)': {
            minHeight: '7.5vw',
            lineHeight: '.9',
            bottom: '-4vw',
            paddingLeft: nup.styles?.per_row > 2 ? '6.4vw' : '10vw',
            paddingRight: nup.styles?.per_row > 2 ? '5vw' : '15.2vw',
        },
        '@media screen and (max-width: 768px)': {
            minHeight: nup.styles?.per_row > 2 ? '7.8vw' : '10.7vw',
            backgroundImage: `url(${nup.styles.message_background_image_mobile?.url}?auto=webp)`,
            paddingLeft: nup.styles?.per_row > 2 ? '6vw' : '9vw',
            paddingRight: nup.styles?.per_row > 2 ? '5vw' : '10vw',
        },
        '@media screen and (max-width: 599px)': {
            bottom: '-20px',
            paddingLeft: '19vw',
            paddingRight: '22vw',
            minHeight: '24vw',
        },
        '@media screen and (max-width: 348px)': {
            minHeight: '25vw',
        },
    }),
    copyBox: ({ nup }) => ({
        display: 'flex',
        lineHeight: '1.1',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& > p': {
            textAlign: nup.styles.presentation_style === 'T3 Hang Tag' ? 'left' : 'center',
            width: '100%',
        },
        // Notice that we have a gradually reducing sclaing factor for the fontSize specified in the CMS.
        // Values scale from unmodified on desktop down to * 0.8 on mobile to make font fit better in smaller mobile containers.
        fontSize: nup.styles.font_size ? `${nup.styles.font_size}rem` : nup.styles?.per_row > 2 ? '1.2rem' : '1.5rem',
        '@media screen and (max-width: 1150px)': {
            lineHeight: '1.2',
            fontSize: nup.styles.font_size ? `${nup.styles.font_size * 0.95}rem` : nup.styles?.per_row > 2 ? '1.2rem' : '1.5rem',
        },
        '@media screen and (max-width: 767px)': {
            fontSize: nup.styles.font_size ? `${nup.styles.font_size * 0.9}rem` : nup.styles?.per_row > 2 ? '1.1rem' : '1.5rem',
        },
        '@media screen and (max-width: 599px)': {
            fontSize: nup.styles.font_size ? `${nup.styles.font_size * 0.8}rem` : nup.styles?.per_row > 2 ? '1.1rem' : '1.5rem',
        },
    }),
    hangTagCopyFonts: ({ nup }) => ({
        '@media screen and (max-width: 1150px)': {
            fontSize: nup.styles?.per_row > 2 ? '1.2vw' : '1.4vw',
        },
        '@media screen and (max-width: 767px)': {
            fontSize: nup.styles?.per_row > 2 ? '1.4vw' : '2vw',
        },
        '@media screen and (max-width: 599px)': {
            fontSize: '4.2vw',
        },
    }),
    detailsLinkBox: {
        paddingBottom: '5px',
    },
    hangTagCTA: ({ nup }) => ({
        display: 'block',
        padding: '0',
        fontFamily: 'latobold, Arial, Verdana, sans-serif',
        fontSize: nup.styles?.per_row > 2 ? '26px' : '30px',
        color: '#4b93af',
        textAlign: 'left',
        lineHeight: '.83',
        marginTop: '5px',
        marginBottom: '5px',
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '@media screen and (max-width: 1399px)': {
            '& span': {
                fontSize: nup.styles?.per_row > 2 ? '1.8vw' : '2vw',
            },
        },
        '@media screen and (max-width: 1100px)': {
            '& span': {
                fontSize: nup.styles?.per_row > 2 ? '1.7vw' : '2vw',
            },
        },
        '@media screen and (max-width: 769px)': {
            '& span': {
                fontSize: nup.styles?.per_row > 2 ? '2vw' : '2.8vw',
            },
        },
        '@media screen and (max-width: 599px)': {
            '& span': {
                fontSize: '5.9vw',
            },
        },
    }),
    btnStyle: ({ nup }) => ({
        fontSize: '1.2rem',
        fontFamily: fontFamilyParsed(nup.styles?.copy_font),
        color: (nup.styles?.cta_copy_color?.color || '#1f1f1f'),
        alignItems: 'center',
        padding: '6px',
        display: 'inline-flex',
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
        '&>svg': {
            height: '20px',
            width: '20px',
            marginLeft: '8px',
        },
    }),
}));

const NUpSimplePanelContents = ({
    data, panelItem, isBot,
}) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles({ nup: data.reference[0], panelItem, isMobile });
    const isDesktop = !isMobile;
    const nup = data.reference[0];
    const isHangTag = nup.styles.presentation_style === 'T3 Hang Tag';
    let messageWrapperSpecial = classes.messageWrapperDefault;
    if (nup.styles?.message_bg_style === 'Tab') messageWrapperSpecial = classes.messageWrapperTab;
    if (isHangTag) messageWrapperSpecial = classes.hangTagMessage;
    const pannelImage = isMobile ? panelItem.background_image_mobile?.url : panelItem.background_image?.url;
    const pannelImageAlt = isMobile ? panelItem.background_image_mobile?.title : panelItem.background_image?.title;
    const pannelCopyHideOnMobile = isMobile ? !panelItem?.hide_copy_on_mobile : true;
    const renderBlock = () => {
        /* If there is no copy or cta copy, it will render as a news tile, Otherwise if a backgroud image is provided,
        it will render as default product tile, else it will render a div of provided dimension / presentation style */
        if (panelItem.copy.length === 0 && !panelItem.cta_copy) {
            return (
                <div className={classes.imageContainer}>
                    <img src={pannelImage} alt={pannelImageAlt} className={classes.newsTileImage} />
                </div>
            );
        }

        if (pannelImage) {
            return (
                <div className={`${classes.imageContainer} ${classes.imageBackground} ${(isHangTag) ? classes.hangTagContainer : ''}`}>
                    {isBot && <img src={panelItem.background_image.url} alt={panelItem.background_image.title} isBot="true" />}
                    {pannelCopyHideOnMobile && (
                        <div className={`${classes.messageWrapper} ${messageWrapperSpecial}`} message="">
                            {panelItem.copy && panelItem.copy.map((copyItem) => (
                                <div className={`${classes.copyBox} ${(isHangTag) ? classes.hangTagCopyFonts : ''}`} key={panelItem.title}>
                                    <ReactMarkdown renderers={transformRenderer} source={copyItem} />
                                    {isMobile && !isHangTag && <span className={classes.ctaCaret}><Caret /></span>}
                                </div>
                            ))}
                            <div className={`${classes.copyBox} ${classes.detailsLinkBox}`} key={panelItem.title}>
                                {panelItem.details_link && <Copy data={detailsAsObj(panelItem.details_link)} />}
                            </div>
                            {/* Hide CTA on mobile */}
                            {(isDesktop || isHangTag) && panelItem.cta_copy.length !== 0 && (
                                <span className={(isHangTag) ? classes.hangTagCTA : classes.btnStyle}>
                                    {panelItem.cta_copy} {(isHangTag) ? '' : <Caret />}
                                </span>
                            )}
                        </div>
                    )}
                </div>
                /* Added Copy components instead of manual switch case to avoid code repetition and to add more functionality to n-up panel. We can use Panel component directly but background image logic has not been implemented in it yet */
            );
        }

        return (
            <div className={classes[data?.reference[0]?.presentation_style]}>
                <Copy data={panelItem.message_collection} />
            </div>
        );
    };

    return renderBlock();
};

NUpSimplePanelContents.propTypes = {
    data: shape({
        entry: shape({
            linking: object,
            copy: array,
            cta_copy: array,
            background_image: object,
            background_image_mobile: object,
        }).isRequired,
        styles: object,
    }).isRequired,
    classes: object.isRequired,
    isBot: bool,
    panelItem: shape({
        background_image: shape({
            url: string.isRequired,
            title: string.isRequired,
            cta_copy: string.isRequired,
        }),
    }).isRequired,
};
NUpSimplePanelContents.defaultProps = {
    isBot: false,
};
export default (NUpSimplePanelContents);
