/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    func, shape, arrayOf, string, bool, object,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { getActiveABTest } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import ResponsiveImage from '../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        backgroundColor: theme?.palette?.white || '#fff',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: 4,
    },
    imgBox: {
        margin: '0 auto',
    },
    tilesContainer: (styles) => ({
        marginTop: 10,
        padding: 15,
        backgroundColor: styles?.tiles_style?.container_background_color?.color,
    }),
    header: (styles) => ({
        marginBottom: 10,
        fontSize: `${styles?.header_styling?.font_size || '22'}px`,
        textAlign: styles?.header_styling?.text_align.toLowerCase() || 'left',
        fontWeight: styles?.header_styling?.font_weight || 100,
        fontFamily: parseFont(styles?.header_styling?.font_family),
        fontStyle: styles?.header_styling?.text_decoration.toLowerCase(),
        color: styles?.header_styling?.font_color?.color || '#000000',
        lineHeight: styles?.header_styling?.line_height,
        letterSpacing: styles?.header_styling?.letter_spacing || '.4px',
        [theme.breakpoints.down(768)]: {
            fontSize: `${styles?.header_styling?.font_size || '32'}px`,
            margin: '0px',
            letterSpacing: styles?.header_styling?.letter_spacing || '.16px',
        },
    }),
    button: {
        display: 'block',
        width: 145,
        height: 35,
        color: theme?.palette?.white,
        textAlign: 'center',
        backgroundColor: theme.palette.cms?.mobileTileButtonBackground || theme.palette.colorPrimary,
        cursor: 'pointer',
        fontWeight: 500,
        fontSize: 14,
        borderRadius: 30,
        textTransform: 'uppercase',
        '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.cms?.mobileTileButtonBackground || theme.palette.colorPrimary,
        },
    },
    img: {
        width: '100%',
        height: 'auto',
    },
    cardContainer: {
        marginTop: 15,
    },
    fullWidthCard: {
        fontSize: 18,
    },
    gridCard: {
        fontSize: 14,
        [theme.breakpoints.down(768)]: {
            fontSize: 16,
            fontFamily: 'LatoRegular, Arial, Verdana, sans-serif',
            lineHeight: '20px',
            color: theme.palette.cms?.textCategoryColor || '#2f2f2f',
            fontWeight: '400',
        },
        [theme.breakpoints.down(350)]: {
            fontSize: 13,
        },

    },
    cardTitle: {
        textAlign: 'left',
    },
    verticalCard: {
        minHeight: 265,
    },
    inlineCard: {
        minHeight: 70,
        paddingRight: '5px',
    },
    link: {
        textDecoration: 'none',
        color: theme?.palette?.black,
    },
    verticalCardTitle: {
        textAlign: 'center',
        fontSize: 18,
        minHeight: 60,
    },
    verticalMiddle: {
        verticalAlign: 'middle',
    },
}));
const Tiles = ({
    block, track, abTestShopByCategory,
}) => {
    if (!block) {
        return <></>;
    }
    const classes = useStyles(block);
    const presentationStyle = block?.tiles_style?.presentation_style?.toLowerCase();
    let fullWidthCard = block?.tiles_style?.full_width_cards || false;
    const headerIcon = block?.header_icon?.url || null;
    let isInlineCards = (presentationStyle === 'inline card');

    const renderButton = (button) => (
        <Link
            className={`${classes.link} ${classes.button}`}
            to={button?.link?.href}
            data-ga-category={button?.tracking_event_category || ''}
            data-ga-action={button?.tracking_event_action || ''}
            data-ga-label={button?.tracking_event_label || ''}
            onClick={() => {
                track({
                    eventCategory: button?.tracking_event_category || '',
                    eventAction: button?.tracking_event_action || '',
                    eventLabel: button?.tracking_event_label || '',
                });
            }}
        >
            <Button
                className={classes.button}
                tabIndex={-1}
            >
                {button?.link?.title}
            </Button>
        </Link>
    );

    const renderInlineCards = (item) => (
        <Link
            className={classes.link}
            to={item?.tile?.card_url?.href}
            data-ga-category={item?.tile?.tracking_event_category || ''}
            data-ga-action={item?.tile?.tracking_event_action || ''}
            data-ga-label={item?.tile?.tracking_event_label || ''}

            onClick={() => {
                track({
                    eventCategory: item?.tile?.tracking_event_category || '',
                    eventAction: item?.tile?.tracking_event_action || '',
                    eventLabel: item?.tile?.tracking_event_label || '',
                });
            }}
        >
            <Grid alignItems="center" className={`${classes.cardRoot} ${presentationStyle === 'inline card' ? classes.inlineCard : classes.verticalCard}`} container>
                <Grid item sm={5} xs={4}>
                    <Grid className={classes.imgBox} item xs={10}>
                        <ResponsiveImage
                            className={classes.img}
                            path={item?.tile?.image?.url}
                            alt={item.tile?.card_url?.title}
                            dimensions={item.tile?.image?.dimension}
                        />
                    </Grid>
                </Grid>
                <Grid
                    className={`${classes.cardTitle} ${fullWidthCard ? classes.fullWidthCard : classes.gridCard}`}
                    item
                    role="region"
                    aria-label={item.tile?.card_url?.title}
                    sm={7}
                    xs={8}
                >
                    {item.tile?.card_url?.title}
                </Grid>
            </Grid>
        </Link>
    );

    const renderVerticalCards = (item) => (
        <Link
            className={classes.link}
            to={item?.tile?.card_url?.href}
            data-ga-category={item?.tile?.tracking_event_category || ''}
            data-ga-action={item?.tile?.tracking_event_action || ''}
            data-ga-label={item?.tile?.tracking_event_label || ''}

            onClick={() => {
                track({
                    eventCategory: item?.tile?.tracking_event_category || '',
                    eventAction: item?.tile?.tracking_event_action || '',
                    eventLabel: item?.tile?.tracking_event_label || '',
                });
            }}
        >
            <Grid className={`${classes.cardRoot} ${classes.verticalCard}`} container>
                <Grid item sm={12} xs={12}>
                    <img className={classes.img} src={item.tile?.image?.url} alt={item.tile?.card_url?.title} />
                </Grid>
                <Grid
                    className={`${classes.cardTitle} ${classes.verticalCardTitle} ${fullWidthCard ? classes.fullWidthCard : classes.gridCard}`}
                    item
                    sm={12}
                    xs={12}
                >
                    <span className={classes.verticalMiddle}>{item.tile?.card_url?.title}</span>
                </Grid>
            </Grid>
        </Link>
    );

    /**
     * AB testing block
     */
    let tilesBlock = block?.tiles_entry || [];
    if (block?.ab_testing?.unique_id === 'shopByCategory') {
        const shopByCategoryVariant = abTestShopByCategory?.type?.toLowerCase?.() || null;
        if (shopByCategoryVariant === 'variant 1') {
            // added default 5 to show as per ticket but it can be managed by optimize
            const showCount = abTestShopByCategory?.mobileItemCount || 5;
            isInlineCards = true; // default vertical
            tilesBlock = tilesBlock?.slice?.(0, showCount);
            fullWidthCard = true;
        } else if (shopByCategoryVariant === 'variant 2') {
            return <></>;
        }
    }

    return (
        <>
            <Grid className={classes.tilesContainer} container>
                <Grid container>
                    <Grid item sm={headerIcon ? 9 : 12} xs={headerIcon ? 9 : 12}>
                        <Grid container>
                            <Grid className={classes.header} item sm={12} xs={12}>{block.heading}</Grid>
                            {block.tiles_header_section?.link?.title && <Grid item sm={12} xs={12}>{renderButton(block.tiles_header_section)}</Grid>}
                        </Grid>
                    </Grid>
                    {headerIcon && (
                        <Grid item sm={3} xs={3}>
                            <img className={classes.img} src={headerIcon} alt={block.heading} />
                        </Grid>
                    )}
                </Grid>
                <Grid className={classes.cardContainer} container spacing={block?.tiles_style?.spacing || 2}>
                    {tilesBlock?.map((item) => (
                        <Grid key={item.tile?.card_url?.href} item xs={fullWidthCard ? 12 : 6}>
                            {isInlineCards ? renderInlineCards(item) : renderVerticalCards(item)}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

Tiles.propTypes = {
    track: func.isRequired,
    block: shape({
        tiles: bool,
        tiles_entry: arrayOf(shape({
            tile: shape({
                card_url: shape({
                    link: string,
                    href: string,
                }),
                image: shape({
                    url: string,
                }),
            }),
        })),
    }).isRequired,
    abTestShopByCategory: object,
};
Tiles.defaultProps = {
    abTestShopByCategory: {},
};
const mapStateToProps = (state) => ({
    abTestShopByCategory: getActiveABTest('shopByCategory')(state),
});
export default connect(
    mapStateToProps,
)(Tiles);
