/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import {
    object, string, bool, shape, array,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NUpSimpleResponsive from './Desktop/NUpSimpleResponsive';
import NUpSimpleCarousel from './Mobile/NUpSimpleCarousel';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import { getIsBot, getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getPresentationFamily, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';

const styles  = (theme) => ({
    block: {
        position: 'relative',
        margin: '0 auto 32px',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
        },
    },
    blockWithGrid: {
        position: 'relative',
        margin: '0 auto',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
        },
    },
});

const NUpRow = ({
    data: blockData, headingAlignment, fixedMessage, isBot, classes, ssrDeviceType, presentationFamily,
}) => {
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
    });
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    if (loading) {
        return (
            <DesktopMobile
                desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
            />
        );
    }
    if (Object.keys(data.reference?.[0]).length > 2) {
        const dataUniqueId = uniqueId(data.reference[0]?.unique_selector);
        const cmsuid = data.reference[0]?.uid || null;
        const isCarousel = data.reference[0]?.styles?.presentation_style === 'Carousel';
        return (
            <>
                <div className={`nup_block ${ffHasHomepageGridStructure ? classes.blockWithGrid : classes.block}`} data-uniqueid={dataUniqueId} style={isCarousel ? { width: '100%' } : {}} cmsuid={cmsuid}>
                    {isCarousel ? (
                        <NUpSimpleCarousel data={data} headingAlignment={headingAlignment} fixedMessage={fixedMessage} isBot={isBot} />
                    ) : (
                        <NUpSimpleResponsive data={data} headingAlignment={headingAlignment} fixedMessage={fixedMessage} isBot={isBot} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />
                    )}
                </div>
            </>
        );
    }
    return <></>;
};

NUpRow.defaultProps = {
    headingAlignment: 'center',
    fixedMessage: false,
    isBot: false,
    ssrDeviceType: 'Desktop',
};

NUpRow.propTypes = {
    data: shape({
        entry: shape({
            linking: object,
            copy: array,
            cta_copy: array,
            background_image: object,
            background_image_mobile: object,
        }).isRequired,
        styles: object,
    }).isRequired,
    headingAlignment: string,
    fixedMessage: bool,
    isBot: bool,
    classes: object.isRequired,
    ssrDeviceType: string,
    presentationFamily: string.isRequired,
};
const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
    ssrDeviceType: getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
});
const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps),
);
export default enhance(NUpRow);
